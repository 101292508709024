import {
  BlockIdSchema,
  IntervalSchema,
  SurferIdSchema,
  ZonedAvailability,
  ZonedAvailabilitySchema,
  dateString,
  pagedResultsSchema,
} from 'shared-types';
import { z } from 'zod';

import { ScheduledTaskSchema } from './activity';
import { ConflictSchema } from './conflict';
import { SurferId } from './ids';
import { PersistedPlanSchema } from './plan';
import {
  AssignedShiftBlockSchema,
  OriginalFillBlockSchema,
  TimeInLieuBlockSchema,
  TimeOffBlockSchema,
} from './schedule';
import { PersistedShiftTemplateSchema } from './shift-templates';
import { UtcIntervalSchema, ZonedIntervalSchema } from './timezone';

export const ZonedScheduledTaskSchema = ScheduledTaskSchema.merge(
  z.object({
    interval: ZonedIntervalSchema,
  }),
);
export type ZonedScheduledTask = z.infer<typeof ZonedScheduledTaskSchema>;

export const ZonedOriginalFillBlockSchema = OriginalFillBlockSchema.merge(
  z.object({
    interval: ZonedIntervalSchema,
    tasks: z.array(ZonedScheduledTaskSchema).optional(),
  }),
);

export const ZonedAssignedShiftBlockSchema = AssignedShiftBlockSchema.merge(
  z.object({
    interval: ZonedIntervalSchema,
    tasks: z.array(ZonedScheduledTaskSchema).optional(),
    originalFillBlock: ZonedOriginalFillBlockSchema.optional(),
  }),
);
export const ZonedTimeOffBlockSchema = TimeOffBlockSchema.merge(
  z.object({
    interval: ZonedIntervalSchema,
    originalFillBlock: ZonedOriginalFillBlockSchema.optional(),
  }),
);
export const ZonedTimeInLieuBlockSchema = TimeInLieuBlockSchema.merge(
  z.object({
    interval: ZonedIntervalSchema,
    originalFillBlock: ZonedOriginalFillBlockSchema.optional(),
  }),
);

export const ZonedBlockSchema = z.discriminatedUnion('type', [
  ZonedAssignedShiftBlockSchema,
  ZonedTimeOffBlockSchema,
  ZonedTimeInLieuBlockSchema,
]);

export type ZonedBlock = z.infer<typeof ZonedBlockSchema>;
export type ZonedAssignedShiftBlock = z.infer<typeof ZonedAssignedShiftBlockSchema>;
export type ZonedTimeOffBlock = z.infer<typeof ZonedTimeOffBlockSchema>;
export type ZonedTimeInLieuBlock = z.infer<typeof ZonedTimeInLieuBlockSchema>;

export const isShiftBlock = (block: ZonedBlock): block is ZonedAssignedShiftBlock => {
  return block.type === 'block-assigned-shift';
};
export const isTimeOffBlock = (block: ZonedBlock): block is ZonedTimeOffBlock => {
  return block.type === 'block-time-off';
};
export const isTimeInLieuBlock = (block: ZonedBlock): block is ZonedTimeInLieuBlock => {
  return block.type === 'block-time-in-lieu';
};
export const isAvailabilityBlock = (
  block?: EnrichedBlock | ZonedAvailability,
): block is ZonedAvailability => {
  return !!block && block.blockType === 'availability-block';
};
export const isEnrichedBlock = (
  block?: EnrichedBlock | ZonedAvailability,
): block is EnrichedBlock => {
  return !!block && block.blockType === 'enriched-block';
};

export const isFillShiftBlock = (block: ZonedBlock): boolean => {
  return isShiftBlock(block) && block.conflictType === 'fill';
};

const UtcScheduledTaskSchema = ScheduledTaskSchema.merge(
  z.object({
    interval: UtcIntervalSchema,
  }),
);

export const UtcOriginalFillBlockSchema = OriginalFillBlockSchema.merge(
  z.object({
    interval: UtcIntervalSchema,
    tasks: z.array(UtcScheduledTaskSchema).optional(),
  }),
);

const UtcAssignedShiftBlockSchema = AssignedShiftBlockSchema.merge(
  z.object({
    interval: UtcIntervalSchema,
    tasks: z.array(UtcScheduledTaskSchema).optional(),
    originalFillBlock: UtcOriginalFillBlockSchema.optional(),
  }),
);
const UtcTimeOffBlockSchema = TimeOffBlockSchema.merge(
  z.object({
    interval: UtcIntervalSchema,
    originalFillBlock: UtcOriginalFillBlockSchema.optional(),
  }),
);
const UtcTimeInLieuBlockSchema = TimeInLieuBlockSchema.merge(
  z.object({
    interval: UtcIntervalSchema,
    originalFillBlock: UtcOriginalFillBlockSchema.optional(),
  }),
);

export const UtcBlockSchema = z.discriminatedUnion('type', [
  UtcAssignedShiftBlockSchema,
  UtcTimeOffBlockSchema,
  UtcTimeInLieuBlockSchema,
]);

export type UtcBlock = z.infer<typeof UtcBlockSchema>;

// This is required as the schema self references
const EnrichedUtcBlockWithoutConflictsSchema = z.object({
  block: UtcBlockSchema,
  shiftTemplate: PersistedShiftTemplateSchema.optional(),
  plan: PersistedPlanSchema.optional(),
});
export type EnrichedUtcBlock = z.infer<typeof EnrichedUtcBlockWithoutConflictsSchema> & {
  conflicts: EnrichedUtcConflict[];
};
export const EnrichedUtcBlockSchema: z.ZodType<EnrichedUtcBlock> =
  EnrichedUtcBlockWithoutConflictsSchema.extend({
    conflicts: z.lazy(() => EnrichedUtcConflictSchema.array()),
  });

export const EnrichedUtcConflictSchema = ConflictSchema.merge(
  z.object({ blocks: EnrichedUtcBlockSchema.array() }),
);
export type EnrichedUtcConflict = z.infer<typeof EnrichedUtcConflictSchema>;

// This is required as the schema self references
const EnrichedBlockWithoutConflictsSchema = z.object({
  block: ZonedBlockSchema,
  shiftTemplate: PersistedShiftTemplateSchema.optional(),
  plan: PersistedPlanSchema.optional(),
  blockType: z.literal('enriched-block'),
});
export type EnrichedBlock = z.infer<typeof EnrichedBlockWithoutConflictsSchema> & {
  conflicts: EnrichedConflict[];
};
export const EnrichedBlockSchema: z.ZodType<EnrichedBlock> =
  EnrichedBlockWithoutConflictsSchema.extend({
    conflicts: z.lazy(() => EnrichedConflictSchema.array()),
  });

export type EnrichedUtcBlocksBySurferId = { [key: SurferId]: EnrichedUtcBlock[] };
export type EnrichedBlocksBySurferId = { [key: SurferId]: EnrichedBlock[] };

export const EnrichedConflictSchema = ConflictSchema.merge(
  z.object({ blocks: EnrichedBlockSchema.array() }),
);
export type EnrichedConflict = z.infer<typeof EnrichedConflictSchema>;

export const EditShiftBlockSchema = z.object({
  data: AssignedShiftBlockSchema,
  selectedBlock: EnrichedBlockSchema,
});

export type EditShiftBlock = z.infer<typeof EditShiftBlockSchema>;

export const CreateShiftBlockSchema = z.object({
  data: AssignedShiftBlockSchema,
  selectedBlock: ZonedAvailabilitySchema.nullish(),
});

export type CreateShiftBlock = z.infer<typeof CreateShiftBlockSchema>;

export type SurferHours = { [key: SurferId]: number };
export type SurferHoursByDay = {
  [key: string]: SurferHours;
};
/** new Shift block endpoints repsonse **/

const RelaxedUtcScheduledTaskSchema = ScheduledTaskSchema.merge(
  z.object({
    interval: IntervalSchema,
  }),
);

export const RelaxedUtcOriginalFillBlockSchema = OriginalFillBlockSchema.merge(
  z.object({
    interval: IntervalSchema,
    tasks: z.array(RelaxedUtcScheduledTaskSchema).optional(),
  }),
);

const RelaxedUtcAssignedBlockSchema = AssignedShiftBlockSchema.merge(
  z.object({
    interval: IntervalSchema,
    tasks: z.array(RelaxedUtcScheduledTaskSchema).optional(),
    originalFillBlock: RelaxedUtcOriginalFillBlockSchema.optional(),
  }),
);

export const RelaxedUtcTimeOffBlockSchema = TimeOffBlockSchema.merge(
  z.object({
    interval: IntervalSchema,
    originalFillBlock: RelaxedUtcOriginalFillBlockSchema.optional(),
  }),
);

export const RelaxedUtcTimeInLieuBlockSchema = TimeInLieuBlockSchema.merge(
  z.object({
    interval: IntervalSchema,
    originalFillBlock: RelaxedUtcOriginalFillBlockSchema.optional(),
  }),
);

export const RelaxedUtcBlockSchema = z.discriminatedUnion('type', [
  RelaxedUtcAssignedBlockSchema,
  RelaxedUtcTimeOffBlockSchema,
  RelaxedUtcTimeInLieuBlockSchema,
]);

const RelaxedEnrichedUtcBlockWithoutConflictsSchema = z.object({
  block: RelaxedUtcBlockSchema,
  shiftTemplate: PersistedShiftTemplateSchema.optional(),
  plan: PersistedPlanSchema.optional(),
});

export type RelaxedEnrichedUtcBlock = z.infer<
  typeof RelaxedEnrichedUtcBlockWithoutConflictsSchema
> & {
  conflicts: RelaxedEnrichedUtcConflict[];
};

export const RelaxedEnrichedUtcBlockSchema: z.ZodType<RelaxedEnrichedUtcBlock> =
  RelaxedEnrichedUtcBlockWithoutConflictsSchema.extend({
    conflicts: z.lazy(() => RelaxedEnrichedUtcConflictSchema.array()),
  });

export const RelaxedEnrichedUtcConflictSchema = ConflictSchema.merge(
  z.object({ blocks: RelaxedEnrichedUtcBlockSchema.array() }),
);
export type RelaxedEnrichedUtcConflict = z.infer<typeof RelaxedEnrichedUtcConflictSchema>;

const EnrichedUtcBlockResponseSchema = z.object({
  block: RelaxedUtcBlockSchema,
  shiftTemplate: PersistedShiftTemplateSchema.optional(),
  plan: PersistedPlanSchema.optional(),
  conflicts: RelaxedEnrichedUtcConflictSchema.array(),
});

export const GetShiftBlockRespSchema = pagedResultsSchema(EnrichedUtcBlockResponseSchema);

export const EditConflictModeSchema = z.union([z.literal('IGNORE'), z.literal('REVERT')]);

export const EditShiftConflictReqSchema = z.object({
  mode: EditConflictModeSchema,
  blockId: BlockIdSchema,
  startDate: dateString,
  endDate: dateString,
  surferId: SurferIdSchema,
});

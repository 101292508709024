import z from 'zod';

export const TimeOffSubtypeSchema = z
  .union([z.literal('Sick'), z.literal('Time off'), z.literal('Holiday')])
  .nullable()
  .optional();
export type TimeOffSubtype = z.infer<typeof TimeOffSubtypeSchema>;

export const TimeOffSubtypeOptions = {
  SICK: 'Sick',
  TIME_OFF: 'Time off',
  HOLIDAY: 'Holiday', // in our db schema, we use 'Holiday', but we should display it as 'Vacation' in the UI
} as const;

// To prevent Cross Site Request Forgery (CSRF) we generate and store a unique id that is also appended to the authorization URL. We then validate by matching the value coming from the response with our stored value.
export const OAUTH_STATE_KEY = 'oauth_random_id';
export const getOAuthState = () => {
  const storedId = sessionStorage.getItem(OAUTH_STATE_KEY);

  if (storedId) {
    return storedId;
  } else {
    const id = crypto.randomUUID();
    sessionStorage.setItem(OAUTH_STATE_KEY, id);
    return id;
  }
};
export const validateOAuthState = () => {
  const stateParam = new URLSearchParams(window.location.search).get('state');
  const storedId = sessionStorage.getItem(OAUTH_STATE_KEY);

  if (!stateParam || !storedId) {
    return false;
  }

  if (stateParam === storedId) {
    sessionStorage.removeItem(OAUTH_STATE_KEY);
    return true;
  }

  return false;
};

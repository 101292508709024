import 'focus-visible';
import { Suspense, lazy } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import theme from './design-system/theme';
import { useRoles } from './hooks/useRoles';
import { Auth0Redirect } from './pages/Login/Auth0Redirect';
import { AuthErrorPage } from './pages/Login/AuthErrorPage';
import { DialpadAuthCallback } from './pages/Login/DialpadAuthCallback';
import { DialpadRedirect } from './pages/Login/DialpadRedirect';
import { LoginPage } from './pages/Login/LoginPage';
import { useAuth } from './providers/AuthProvider';
import { SharedRoutes } from './routes/appRoutes';
import { CenteredSpinner } from './shared-components/CenteredSpinner';
import { getLocalStorageItem, setLocalStorageItem } from './utils/localStorage';
import { getUserName } from './utils/user';

export const App = () => {
  const { isLoading, error, user } = useAuth();

  const { boot } = useIntercom();
  if (user) {
    boot({
      name: getUserName(user),
      email: user.email,
      actionColor: theme.colors.purple[30],
      backgroundColor: theme.colors.yellow[30],
      customLauncherSelector: '#custom-intercom-launcher',
      hideDefaultLauncher: true,
      customAttributes: {
        roles: user.roles,
        organization: user.organizationId,
        managerId: user.managerId,
        isDialpad: user.isDialpad,
      },
    });
  }

  if (isLoading) {
    return <CenteredSpinner />;
  }

  if (error) {
    return <AuthErrorPage error={error} />;
  }

  return (
    <Switch>
      <Route path={SharedRoutes.DIALPAD_AUTH_CALLBACK} component={DialpadAuthCallback} />
      <Route path={SharedRoutes.LOGIN_AUTH0} component={Auth0Redirect} />
      <Route path={SharedRoutes.LOGIN_DIALPAD} component={DialpadRedirect} />
      <Route path={SharedRoutes.LOGIN} component={LoginPage} />

      <AuthenticatedRoutes />
    </Switch>
  );
};

const ManagerRouterLazy = lazy(() =>
  import('./routes/ManagerRouter').then(({ ManagerRouter }) => ({
    default: ManagerRouter,
  })),
);
const ManagerRouter = () => (
  <Suspense fallback={<CenteredSpinner />}>
    <ManagerRouterLazy />
  </Suspense>
);

const SurferRouterLazy = lazy(() =>
  import('./routes/SurferRouter').then(({ SurferRouter }) => ({
    default: SurferRouter,
  })),
);
const SurferRouter = () => (
  <Suspense fallback={<CenteredSpinner />}>
    <SurferRouterLazy />
  </Suspense>
);

export class InvalidRoleError extends Error {
  readonly _tag = 'InvalidRole';

  error: string;
  error_description: string;

  constructor(message: string, error: string) {
    super(message);
    this.error = error;
    this.error_description = message;
  }
}

function AuthenticatedRoutes() {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const roles = useRoles();

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: SharedRoutes.LOGIN,
          search: location?.search,
        }}
      />
    );
  }

  const view = getLocalStorageItem('view');
  const isManager = roles.includes('manager');
  const isSurfer = roles.includes('surfer');

  // first we try to respect the user's roles and their `view` state
  if (isManager && (!view || view === 'manager')) {
    return <ManagerRouter />;
  } else if (isSurfer && (!view || view === 'surfer')) {
    return <SurferRouter />;
  }

  // next handle the case where have a valid role that does not respect the `view` state
  if (isManager) {
    setLocalStorageItem('view', 'manager');
    return <ManagerRouter />;
  } else if (isSurfer) {
    setLocalStorageItem('view', 'surfer');
    return <SurferRouter />;
  }

  // if they do not have a valid role then show auth error page
  const error = new InvalidRoleError('User does not have valid role', 'Access denied');
  return <AuthErrorPage error={error} />;
}

export default App;

import { ChakraProvider } from '@chakra-ui/react';
import '@dialpad/dialtone-tokens/dist/css/tokens-base-light.css';
import '@dialpad/dialtone-tokens/dist/css/tokens-dp-light.css';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';

import App from './App';
import './design-system/styles/main.scss';
import theme from './design-system/theme';
import AnalyticsProvider from './providers/AnalyticsProvider';
import { AuthProvider } from './providers/AuthProvider';
import { queryClientConfig } from './queries/api';
import { SESSION_ID } from './session';

// https://vite.dev/guide/build#load-error-handling
window.addEventListener('vite:preloadError', event => {
  event.preventDefault();
  window.location.reload(); // Refresh the page on preload error
});

// For some reason DONT_USE_SENTRY doesn't not work in review apps, this is a hack to skip Sentry in review apps
const isHerokuReviewApp = window.location.host.includes('herokuapp');

if (
  (import.meta.env.MODE === 'staging' || import.meta.env.MODE === 'production') &&
  import.meta.env.DONT_USE_SENTRY !== 'true' &&
  !isHerokuReviewApp
) {
  Sentry.init({
    dsn: 'https://e6cef07c07c84f7ca69f33d0cf82870a@o1027089.ingest.sentry.io/6288988',
    integrations: [
      new Sentry.BrowserProfilingIntegration(),
      Sentry.browserTracingIntegration({
        enableInp: true,
      }),
    ],
    environment: import.meta.env.MODE,
    release: __COMMIT_HASH__,
    tracesSampleRate: 1.0,
    profilesSampleRate: 1.0,
  });

  Sentry.configureScope(function (scope) {
    scope.setTag('session_id', SESSION_ID);
  });
}

if (window.location.host.includes('localhost') || window.location.host.includes('app.stage')) {
  const favicon = document.getElementById('favicon') as HTMLLinkElement;
  if (favicon) {
    favicon.href = '/favicon-staging.svg';
  }
}

async function prepare() {
  // Start MSW for development use only
  if (import.meta.env.VITE_MSW_ENABLED) {
    const { worker } = await import('./mocks/browser');
    worker.start();
  }

  return Promise.resolve();
}

const INTERCOM_APP_ID = 'rjsxlzgp';

const queryClient = new QueryClient(queryClientConfig);

// load the react-query devtool component only when needed
const ReactQueryDevtoolsProduction = React.lazy(() =>
  import('@tanstack/react-query-devtools/build/modern/production.js').then(d => ({
    default: d.ReactQueryDevtools,
  })),
);

const Main = () => {
  // add a method in the window object `toggleDevtools` to enable the devtools
  // in a production build to help when debugging issues that cannot reproduced easily.
  const [showDevtools, setShowDevtools] = React.useState(false);

  React.useEffect(() => {
    // @ts-ignore
    window.toggleDevtools = () => setShowDevtools(old => !old);
  }, []);

  return (
    <BrowserRouter>
      <AuthProvider>
        <IntercomProvider appId={INTERCOM_APP_ID} initializeDelay={5000}>
          <AnalyticsProvider>
            <QueryClientProvider client={queryClient}>
              <ChakraProvider theme={theme}>
                <App />
              </ChakraProvider>
              {/* include react-query devtool for inspecting the cache */}
              <ReactQueryDevtools initialIsOpen={false} />
              {showDevtools && (
                <React.Suspense fallback={null}>
                  <ReactQueryDevtoolsProduction />
                </React.Suspense>
              )}
            </QueryClientProvider>
          </AnalyticsProvider>
        </IntercomProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};

// Don't load the app until MSW has started (if enabled)
prepare().then(() => {
  const container = document.getElementById('root');
  const root = createRoot(container as HTMLElement);
  root.render(
    <React.StrictMode>
      <Main />
    </React.StrictMode>,
  );
});
